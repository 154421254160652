import React from "react";
import "./App.css";

function App() {
  return (
    <>
      <section className="hero-section">
        <div>
          <div className="logo-title">
            <img
              className="logo-image"
              alt="midas-gold"
              src="/images/header-logo.png"
            />
          </div>
          <div className="content-center" style={{ marginTop: "48px" }}>
            <h1 className="header-text dotgothic16">
              Help save the Xerath Gold, and you’ll be rewarded with a real gold
              from the Earth
            </h1>
          </div>

          <div className="content-center" style={{ marginTop: "24px" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://x.com/midasgoldnft"
            >
              <div>
                <svg
                  className="socmed-icon-size"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="X White">
                    <path
                      id="Vector"
                      d="M18.3263 1.90393H21.6998L14.3297 10.3274L23 21.7899H16.2112L10.894 14.838L4.80995 21.7899H1.43443L9.31743 12.78L1 1.90393H7.96111L12.7674 8.25826L18.3263 1.90393ZM17.1423 19.7707H19.0116L6.94539 3.81706H4.93946L17.1423 19.7707Z"
                      fill="#eaddb7"
                    />
                  </g>
                </svg>
              </div>
            </a>
            <div style={{ marginLeft: "24px" }}>
              <svg
                className="socmed-icon-size "
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Telegram White" clipPath="url(#clip0_1008_4184)">
                  <path
                    id="Vector"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.3334 16C32.3334 24.8366 25.1699 32 16.3334 32C7.49682 32 0.333374 24.8366 0.333374 16C0.333374 7.16344 7.49682 0 16.3334 0C25.1699 0 32.3334 7.16344 32.3334 16ZM16.9067 11.8119C15.3505 12.4592 12.2402 13.7989 7.5759 15.8311C6.81848 16.1323 6.42172 16.427 6.3856 16.7151C6.32456 17.202 6.93432 17.3937 7.76467 17.6548C7.87761 17.6903 7.99465 17.7271 8.11462 17.7661C8.93156 18.0317 10.0305 18.3424 10.6018 18.3547C11.12 18.3659 11.6983 18.1523 12.3369 17.7138C16.6949 14.772 18.9445 13.2851 19.0858 13.253C19.1854 13.2304 19.3235 13.202 19.4171 13.2851C19.5106 13.3683 19.5014 13.5258 19.4915 13.568C19.4311 13.8255 17.0375 16.0508 15.7989 17.2024C15.4127 17.5614 15.1388 17.816 15.0828 17.8742C14.9574 18.0045 14.8295 18.1277 14.7067 18.2461C13.9477 18.9778 13.3786 19.5264 14.7382 20.4224C15.3916 20.8529 15.9144 21.209 16.436 21.5642C17.0056 21.9521 17.5738 22.339 18.3089 22.8209C18.4961 22.9437 18.675 23.0712 18.8492 23.1954C19.5121 23.668 20.1077 24.0926 20.8435 24.0248C21.271 23.9855 21.7127 23.5835 21.9369 22.3845C22.467 19.5509 23.5089 13.4113 23.7497 10.8813C23.7708 10.6596 23.7442 10.376 23.7229 10.2514C23.7016 10.1269 23.6571 9.94948 23.4952 9.81813C23.3035 9.66258 23.0076 9.62978 22.8752 9.63211C22.2734 9.64271 21.3501 9.96376 16.9067 11.8119Z"
                    fill="#eaddb7"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1008_4184">
                    <rect
                      width="32"
                      height="32"
                      fill="#eaddb7"
                      transform="translate(0.333374)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </section>
      <section>
        <p className="middle-label press-start-2p">PROTECT XERATH GOLD</p>
      </section>
      <section className="content-container">
        <div>
          <div className="container-image">
            <img
              className="content-image"
              alt="midas-gold-art"
              src="/images/main-art.JPG"
            />
          </div>
          <div className="content-center" style={{ marginTop: "24px" }}>
            <p className="content-text dotgothic16">
              Meet Xerath planet with its exotic red and rocky landscape filled
              with gold deep in its core. The livelihood of the Xerath tribe.
              But now, ominous rumblings echoed through the planet as news of
              Earth's impending invasion spread like wildfire among the Xerath
              tribe.
              <br />
              <br />
              <b>
                Led by their leader RED, the Xerathians embarked on a daring
                mission: To protect Xerath gold mines.
              </b>
            </p>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "100px" }} className="content-center">
        <div>
          {/* <img alt="lantern" 
          
          src="/images/header-logo.png"
          /> */}
        </div>
        <p className="middle-label press-start-2p">DETAILS</p>
      </section>
      <footer style={{ paddingBottom: "56px" }}>
        <p className="footer-text dotgothic16">
          1,224 Supply | GTD WL
          <br />
          Freemint
          <br />
          No Discord. Our mission starts on Telegram (Coming Soon).
        </p>
      </footer>
    </>
  );
}

export default App;
